<template>
  <div
    class="h-full bg-gray-800 items-center flex flex-col justify-center"
    :class="{ 'min-h-screen': !preview_mode }"
  >
    <div
      class="
        py-5
        mt-12
        w-11/12
        max-w-full
        bg-white
        rounded-lg
        text-center
        sm:max-w-lg
        items-center
        border border-gray-200
      "
    >
      <div v-if="subscriber">
        <!-- Business Logo -->
        <div v-if="businessLogo" class="flex justify-center items-center">
          <img class="h-10" :src="businessLogo" />
        </div>
        <div v-else class="flex justify-center">
          <app-text-icon :text="business?.name" icon_outline="BriefcaseIcon" />
        </div>

        <hr class="mt-3" />

        <!-- Package Details -->
        <div class="text-left p-5 bg-gray-50 border-b space-y-2">
          <div class="flex flex-wrap justify-between text-lg">
            <div v-if="loading">
              <paragraph-shimmer
                :is-loading="true"
                :lines="1"
                class="w-28"
                :random-size="true"
              />
            </div>
            <div v-else>
              <p class="font-medium mr-3">
                {{
                  !$lodash.isEmpty(packages.name)
                    ? packages.name
                    : $t("subscription.table_header.package_name")
                    
                }}
              </p>
            </div>
            <div v-if="loading">
              <paragraph-shimmer
                :is-loading="true"
                :lines="1"
                class="w-28"
                :random-size="true"
              />
            </div>
            <div v-else>
              <p class="font-medium">
                {{
                  $formats.currency(
                    packages.currency ?? "MYR",
                    packages.amount ?? 0
                  )
                }}/{{
                  packageFrequency(packages) ?? $t("subscription.package.term")
                }}
              </p>
            </div>
          </div>
          <div>
            <app-text-readmore
              class="text-gray-400 text-sm"
              :text="packages.description"
            />
          </div>
        </div>

        <div class="text-left p-5 bg-gray-50 border-t border-b space-y-2">
          <p>{{ $t("subscription.package.package_term") }}</p>
          <div class="flex flex-wrap justify-between text-lg">
            <div v-if="loading">
              <paragraph-shimmer
                :is-loading="true"
                :lines="1"
                class="w-28"
                :random-size="true"
              />
            </div>
            <div v-else>
              <p v-if="packages.frequency_num" class="font-medium mr-3">
                {{ packages.frequency_num }}
                {{ packageTerm(packages) }}
              </p>
              <p v-else class="font-medium mr-3">
                {{ $t("subscription.package.package_term_placeholder") }}
              </p>
            </div>
            <div v-if="loading">
              <paragraph-shimmer
                :is-loading="true"
                :lines="1"
                class="w-28"
                :random-size="true"
              />
            </div>
            <div v-else>
              <p class="font-medium text-right">
                {{
                  $formats.currency(
                    packages.currency ?? "MYR",
                    getTotalAmount(packages) ?? 0
                  )
                }}
              </p>
              <p v-if="packages.frequency_num" class="text-gray-400 text-sm">
                {{ packages.frequency_num }}
                {{ $t("subscription.package.billing_period") }}
              </p>
              <p v-else class="text-gray-400 text-sm">
                {{ $t("subscription.package.no_of_billing_period") }}
              </p>
            </div>
          </div>
        </div>

        <!-- Amount To Pay -->
        <div class="text-left p-5 space-y-2">
          <p class="text-gray-500 text-sm">
            {{ $t("subscription.package.price_per_billing") }}
          </p>
          <div v-if="loading">
            <paragraph-shimmer
              :is-loading="true"
              :lines="1"
              class="w-28"
              :random-size="true"
            />
          </div>
          <div v-else>
            <p class="text-2xl font-medium">
              {{
                $formats.currency(
                  packages.currency ?? "MYR",
                  packages.amount ?? 0
                )
              }}/{{ packageFrequency(packages) ?? "-" }}
            </p>
          </div>
        </div>

        <!-- Create Package button -->
        <div class="px-5 pt-3">
          <app-button type="submit" class="w-full">
            {{ edit ? $t("subscription.edit_package") : $t("subscription.create_package") }}
          </app-button>
        </div>
      </div>
      <div v-else>{{ $t("general.no_results") }}</div>
    </div>
    <div class="py-8">
      <app-logo-power-by />
    </div>
  </div>
</template>

<script>
import { ParagraphShimmer } from "vue3-shimmer";
import PACKAGE_FREQUENCY from "@/utils/const/package_frequency";

export default {
  components: {
    ParagraphShimmer,
  },
  computed: {
    subscriber() {
      return this.$store.getters["subscriptionStore/subscriber"];
    },
    packages() {
      return this.$store.getters["subscriptionStore/package"];
    },
    loading() {
      return this.$store.getters["subscriptionStore/loading"];
    },
    business() {
      return this.$store.getters["businessStore/business"];
    },
    businessLogo() {
      return this.business?.media?.find(
        (media) => media.collection_name == "logo"
      )?.original_url;
    },
  },
  props: {
    preview_mode: {
      type: Boolean,
      default: false,
    },
    edit: {
      type: Boolean,
      default: false,
    }
  },
  async mounted() {
    this.getDataForPreview();
  },
  methods: {
    async getDataForPreview() {
      let result = await this.$store.dispatch(
        "businessStore/getBusinessFromSelectedRoleBusinessId"
      );
      this.business = result.data;
    },

    getTotalAmount(data) {
      return data.amount * data.frequency_num;
    },

    packageFrequency(data) {
      switch (data.frequency) {
        case PACKAGE_FREQUENCY.DAILY:
          return "day";
        case PACKAGE_FREQUENCY.WEEKLY:
          return "week";
        case PACKAGE_FREQUENCY.MONTHLY:
          return "month";
        case PACKAGE_FREQUENCY.YEARLY:
          return "year";
      }
    },

    packageTerm(data) {
      switch (data.frequency) {
        case PACKAGE_FREQUENCY.DAILY:
          return data.frequency_num > 1 ? "days" : "day";
        case PACKAGE_FREQUENCY.WEEKLY:
          return data.frequency_num > 1 ? "weeks" : "week";
        case PACKAGE_FREQUENCY.MONTHLY:
          return data.frequency_num > 1 ? "months" : "month";
        case PACKAGE_FREQUENCY.YEARLY:
          return data.frequency_num > 1 ? "years" : "year";
      }
    },
  },
};
</script>