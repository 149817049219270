<template>
  <transition>
    <div class="flex flex-col md:flex-row">
      <div
        :class="[
          'flex flex-col w-full py-2 md:py-10 px-4 md:px-10',
          showPreview ? 'md:w-1/2' : 'md:w-4/5',
        ]"
      >
        <p class="mb-5 font-semibold text-xl">
          {{ $t("subscription.package_list_title") }}
        </p>
        <form class="flex flex-col items-start" @submit.prevent="createPackage">
          <app-form-input
            type="text"
            :labelText="$t('subscription.package.package_name')"
            :placeholder="$t('subscription.package.package_name_placeholder')"
            class="w-full mb-5"
            v-model="packages.name"
            :errorMessage="errors.name"
          />
          <app-form-input
            class="w-full mb-5"
            type="text"
            :labelText="$t('subscription.package.package_description')"
            :placeholder="
              $t('subscription.package.package_description_placeholder')
            "
            v-model="packages.description"
            :errorMessage="errors.description"
          />
          <app-form-input-with-select
            class="w-full mb-5"
            v-model="packages.amount"
            type="number"
            step=".01"
            :labelText="$t('subscription.package.amount_of_package')"
            :placeholder="$t('payment.create.amount_placeholder')"
            :errorMessage="errors.amount"
            @input="validateAmount"
            maxLength="7"
            v-model:option="packages.currency"
            :options="[
              {
                id: 1,
                desc: 'MYR',
              },
            ]"
          />
          <app-form-input-with-select
            class="w-full mb-5"
            v-model="packages.frequency_num"
            type="number"
            :labelText="$t('subscription.package.package_term')"
            :placeholder="$t('subscription.package.package_term_placeholder')"
            :errorMessage="errors.frequency_num"
            v-model:option="packages.frequency"
            :allignOptionsRight="true"
            :options="[
              {
                id: 1,
                desc: 'daily',
              },
              {
                id: 2,
                desc: 'weekly',
              },
              {
                id: 3,
                desc: 'monthly',
              },
              {
                id: 4,
                desc: 'yearly',
              },
            ]"
          />
          <app-form-input-with-select
            class="w-full mb-16"
            v-model="packages.start_after_num"
            type="number"
            :labelText="$t('subscription.package.start_package_cycle_after')"
            :placeholder="$t('subscription.package.start_package_cycle_after')"
            :errorMessage="errors.start_after_num"
            v-model:option="packages.start_after_type"
            :allignOptionsRight="true"
            :options="[
              {
                id: 1,
                desc: 'days',
              },
              {
                id: 2,
                desc: 'weeks',
              },
              {
                id: 3,
                desc: 'months',
              },
            ]"
          />
          <app-button
            type="submit"
            :loading="loading"
            class="w-full"
            :showProceedIcon="true"
            :disabled="disableButtonPackage()"
            >{{
              edit
                ? $t("subscription.edit_package")
                : $t("subscription.create_package")
            }}
          </app-button>
        </form>
      </div>
      <div
        :class="[
          'md:overflow-y-auto md:border-l py-4 md:py-8 px-4 md:px-10 flex flex-col',
          showPreview ? 'md:w-1/2' : 'md:w-1/5',
        ]"
      >
        <div
          :class="[
            'flex flex-col md:flex-row items-center md:space-x-4',
            showPreview ? '' : 'justify-center',
          ]"
        >
          <p
            class="
              w-full
              text-center
              md:text-left md:w-auto
              text-xl
              font-semibold
              mb-2
              md:mb-0
            "
            v-if="showPreview"
          >
            {{ $t("subscription.subscriber.preview_title") }}
          </p>
          <app-button
            :primary="false"
            :showf70Icon="false"
            width="w-full md:w-auto"
            :class="[togglePreview ? 'mb-0' : 'mb-4']"
            @click="togglePreview"
          >
            {{
              showPreview
                ? $t("subscription.subscriber.preview_hide")
                : $t("subscription.subscriber.preview_show")
            }}
          </app-button>
        </div>

        <div class="mt-4 md:mt-8 flex flex-col" v-if="showPreview">
          <div
            class="
              bg-gray-200
              rounded-t-md
              flex flex-row
              px-1
              py-2
              items-center
              space-x-1
              md:space-x-2
            "
          >
            <div class="flex flex-row space-x-1">
              <div class="bg-gray-300 rounded-lg w-2 md:w-3 h-2 md:h-3"></div>
              <div class="bg-gray-300 rounded-lg w-2 md:w-3 h-2 md:h-3"></div>
              <div class="bg-gray-300 rounded-lg w-2 md:w-3 h-2 md:h-3"></div>
            </div>
            <div
              class="
                flex flex-row
                bg-gray-100
                md:w-full
                rounded-md
                px-1
                py-1
                items-center
                justify-center
              "
            >
              <LockClosedIcon class="w-3 h-3 mr-1" />
              <p class="text-xs">{{ package_url }}</p>
            </div>
          </div>

          <PackagePreviewPage
            :preview_mode="true"
            :edit="edit"
            class="rounded-b-md"
          />
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
import { LockClosedIcon } from "@heroicons/vue/solid";
import PackagePreviewPage from "@/views/pages/main/subscription/package/package-preview-page";
export default {
  components: {
    LockClosedIcon,
    PackagePreviewPage,
  },
  data() {
    return {
      showPreview: true,
      edit: false,
    };
  },
  computed: {
    loading() {
      return this.$store.getters["subscriptionStore/loading"];
    },
    packages() {
      return this.$store.getters["subscriptionStore/package"];
    },
    listPackages() {
      return this.$store.getters["subscriptionStore/list_packages"];
    },
    packageFrequencies() {
      return this.$store.getters["subscriptionStore/packageFrequencies"];
    },
    errors() {
      return (
        this.$store.getters["subscriptionStore/errors"] ?? this.emptyErrors
      );
    },
    package_url() {
      return (
        process.env.VUE_APP_BASE_URL +
        "subscription/activation/<" +
        this.$t("general.generated_id") +
        ">"
      );
    },
  },
  mounted() {
    this.edit = this.$route.query?.edit;
    this.setDefaultFrequency();
    this.getListPackages();
  },
  methods: {
    validateAmount(event) {
      const amount = parseFloat(event.target.value.trim());
      if (amount > this.maxAmount) {
        this.errors.amount = [
          this.$t("payment.create.max_amount", {
            max_amount: this.$formats.currency(
              this.packages?.currency,
              this.maxAmount
            ),
          }),
        ];
        return;
      }

      if (amount < this.minAmount) {
        this.errors.amount = [
          this.$t("payment.create.min_amount", {
            min_amount: this.$formats.currency(
              this.packages?.currency,
              this.minAmount
            ),
          }),
        ];
        return;
      }

      this.errors.amount = [];
    },

    disableButtonPackage() {
      if(this.edit){
        return false;
      }
      else if(!this.$lodash.isNil(this.listPackages)) {
        if(this.listPackages.length >= 5)
          return true;
      }
      else {
        return false;
      }
    },

    togglePreview() {
      this.showPreview = !this.showPreview;
    },

    setDefaultFrequency() {
      this.packages.frequency = "daily";
      this.packages.start_after_type = "days";
    },

    getListPackages() {
      this.$store.dispatch("subscriptionStore/retrievePackagesByBusinessId");
    },

    async createPackage(form) {
      const result = await this.$store.dispatch(
        "subscriptionStore/createOrUpdatePackage",
        this.packages
      );

      if (this.$lodash.isEmpty(result.errors)) {
        this.$store.dispatch("subscriptionStore/resetPackage");
        this.$router.push({
          name: "subscription.package.index",
        });
      }
    },

    resetState() {
      this.$store.commit("subscriptionStore/setErrorMessage");
    },
  },
};
</script>